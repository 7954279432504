import { ChevronRightIcon, cn } from '@mr-yum/frontend-ui'
import pluralize from 'pluralize'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import { IconCard } from '@/components/Common/IconCard'
import { SvgImage } from '@/components/Common/SvgImage'
import { RewardIcon } from '@/components/Loyalty/RewardIcon'
import { ALLOWED_COMMON_ELEMENTS } from '@/components/Shared/Markdown'
import { ProgramPayload } from '@/gql/graphql'

interface Image {
  as: string
  src: string
  color?: string
}

const LogoImage = ({ image }: { image: Image }) => {
  switch (image.as) {
    case 'svg':
      return <SvgImage svg={image.src} />
    case 'img':
      return <img src={image.src} />
    default:
      return null
  }
}

const backgroundImage = (image: Image) => {
  switch (image.as) {
    case 'svg':
      return `no-repeat ${image.color} url("data:image/svg+xml;utf8,${encodeURIComponent(image.src)}")`
    case 'img':
      return `no-repeat ${image.color} url(${image.src})`
    default:
      return 'none'
  }
}

const transformDescription = (
  description: string,
  replacements: Map<string, string>,
) => {
  return Array.from(replacements.entries()).reduce((prev, [key, value]) => {
    return prev.replace(new RegExp(`%{${key}}`, 'g'), value)
  }, description)
}

export const RewardMembershipCTA = ({
  href,
  venueName,
  program,
  cta,
  onClick,
  defaultDescription,
}: {
  href?: string
  venueName?: string
  program: Pick<ProgramPayload, 'id' | 'name' | 'logo' | 'configuration'>
  cta: string
  onClick?: () => void
  defaultDescription?: string
}) => {
  const ctaConfig = program?.configuration?.cta?.[cta]

  if (!ctaConfig) return null

  const { background, logo, description, action, textColor } = ctaConfig
  const replacements = new Map<string, string>(
    venueName ? [['venueName', venueName]] : [],
  )

  return (
    <IconCard
      style={{
        ...(background
          ? {
              background: backgroundImage(background),
            }
          : {}),
        ...(textColor ? { color: textColor } : {}),
      }}
      icon={logo ? <LogoImage image={logo} /> : null}
      description={
        <p className={cn({ 'pl-16': !logo })}>
          <ReactMarkdown
            allowedElements={ALLOWED_COMMON_ELEMENTS}
            unwrapDisallowed
            components={{
              p: ({ children }) => (
                <p className="font-['Manrope'] text-base font-medium leading-tight text-inherit">
                  {children}
                </p>
              ),
              a: ({ children, href }) => (
                <a
                  className="text-inherit underline"
                  target="_blank"
                  href={href}
                  rel="noreferrer"
                >
                  {children}
                </a>
              ),
            }}
          >
            {transformDescription(
              description ?? defaultDescription,
              replacements,
            )}
          </ReactMarkdown>
        </p>
      }
      showIndicator={!!(action || href)}
      indicatorIcon={<ChevronRightIcon className="self-center" />}
      onClick={onClick}
      data-testid="reward-membership-cta"
      {...(action
        ? {
            href: action,
            target: '_blank',
            rel: 'noreferrer',
          }
        : {
            href,
          })}
      className="items-center"
    />
  )
}

export function RewardSummaryInfoBanner({
  program,
  rewards,
  points,
}: {
  program: Pick<ProgramPayload, 'id' | 'name' | 'logo' | 'configuration'>
  rewards: number
  points: number
}) {
  const ctaConfig = program?.configuration?.cta?.['rewardsSummary']
  if (!ctaConfig) return null
  const { background, textColor, logo } = ctaConfig

  const noRewards = points === 0 && rewards === 0
  const pointsText = pluralize('point', points, true)
  const rewardsText = pluralize('reward', rewards, true)

  const description = `You have **${pointsText}** and **${rewardsText}** available.${noRewards ? ' **Order now** to earn points.' : ''}`

  return (
    <IconCard
      style={{
        ...(background
          ? {
              background: backgroundImage(background),
            }
          : {}),
        ...(textColor ? { color: textColor } : {}),
      }}
      icon={logo ? <LogoImage image={logo} /> : null}
      description={
        <p className={cn({ 'pl-16': !logo }, 'text-500')}>
          <ReactMarkdown
            allowedElements={ALLOWED_COMMON_ELEMENTS}
            unwrapDisallowed
            components={{
              p: ({ children }) => (
                <p className="font-['Manrope'] text-base font-medium leading-tight text-inherit">
                  {children}
                </p>
              ),
            }}
          >
            {description}
          </ReactMarkdown>
        </p>
      }
      showIndicator
      indicatorIcon={<RewardIcon count={rewards} />}
      data-testid="reward-membership-cta"
      className="items-center"
    />
  )
}
