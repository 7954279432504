import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button } from '@mr-yum/frontend-ui'
import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useLoggerLegacy } from '@/hooks/useLogger'
import { config } from '@/lib/config'
import { routes } from '@/lib/routes'

interface Props {
  venueSlug: string
}

export const LoginCTA = ({ venueSlug }: Props) => {
  const { asPath } = useRouter()
  const { logEventLegacy } = useLoggerLegacy()
  const loginRoute =
    config.environment === 'production' ? routes.login : routes.loginSsr

  return (
    <div
      className="rounded-2xl bg-surface-subtle p-5 text-foreground"
      data-testid="login-cta"
    >
      <h3 className="mb-2 my-label-md">
        <FormattedMessage
          defaultMessage="Log in to view your past orders"
          id="YxM3Iq"
        />
      </h3>

      <p className="mb-3 my-body-md">
        <FormattedMessage
          defaultMessage="View receipts and see your loyalty points when you login via SMS."
          id="q+JZnA"
        />
      </p>

      <RouteLink route={loginRoute} pathData={{ venueSlug, returnTo: asPath }}>
        <Button
          size="sm"
          className="!py-3"
          onClick={() =>
            logEventLegacy('Clicks on Log in CTA in guest profile page')
          }
          as="a"
          fullWidth
        >
          <FormattedMessage defaultMessage="Log in" id="odXlk8" />
        </Button>
      </RouteLink>
    </div>
  )
}
