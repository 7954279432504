import { GiftOutlineIcon } from '@mr-yum/frontend-ui'
import React from 'react'

export function RewardIcon({ count }: { count: number }) {
  return (
    <div className="relative mr-2 mt-2">
      <div className="absolute right-[-6px] top-[-6px] z-0 flex h-4 w-4 justify-center rounded-full bg-[#39A256] align-middle text-[10px] font-bold text-green-50">
        {count}
      </div>
      <GiftOutlineIcon />
    </div>
  )
}
