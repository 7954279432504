import { Button } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CombinedError, useMutation } from 'urql'

import {
  UnlinkGuestFromMembershipDocument,
  VenueMembershipPayload,
} from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'

export type UnlinkMembershipError = Error | CombinedError

export type OnUnlinkMembershipError = (
  error: UnlinkMembershipError,
) => Promise<any>

export type UnlinkMembershipSuccess = {
  success: boolean
}

export type OnUnlinkMembershipSuccess = (
  data?: UnlinkMembershipSuccess,
) => Promise<any>

interface Props {
  venueId: string
  guestId: string
  program: Pick<VenueMembershipPayload, 'id' | 'configuration' | 'membership'>
  onError: OnUnlinkMembershipError
  onSuccess: OnUnlinkMembershipSuccess
}

export const RewardMembershipUnlinkCTA = ({
  guestId,
  program,
  onError,
  onSuccess,
}: Props) => {
  const intl = useIntl()
  const { logEventLegacy } = useLoggerLegacy()
  const { configuration, membership } = program
  const [
    { fetching: unlinkGuestFromMembershipLFetching },
    unlinkGuestFromMembership,
  ] = useMutation(UnlinkGuestFromMembershipDocument)

  const handleSubmit = useCallback(async () => {
    try {
      const { error, data } = await unlinkGuestFromMembership({
        input: {
          programId: program.id,
          membershipId: membership.id!,
          guestId,
        },
      })
      if (error || !data?.unlinkGuestFromMembership.success) {
        return onError(
          new Error(
            intl.formatMessage({
              defaultMessage: 'Unable to unlink membership. Please try again',
              id: 'zAWXpA',
            }),
          ),
        )
      }
      await onSuccess()
    } catch (error) {
      return onError(error)
    }
  }, [
    intl,
    program,
    membership,
    guestId,
    onError,
    onSuccess,
    unlinkGuestFromMembership,
  ])

  if (configuration.customerCanUnlinkMembership === true) {
    return (
      <div
        className="rounded-2xl bg-surface-subtle p-5 text-foreground"
        data-testid="unlink-membership-cta"
      >
        <p className="mb-3 my-body-md">
          <FormattedMessage
            defaultMessage="Your <b>{displayName} account</b> is linked and you will earn points with every purchase."
            id="XnHl0z"
            values={{
              displayName: configuration.displayName,
              b: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </p>

        <Button
          size="sm"
          className="!py-3"
          onClick={async () => {
            logEventLegacy(
              'Clicks on Unlink membership CTA in guest profile page',
            )
            await handleSubmit()
          }}
          as="a"
          isLoading={unlinkGuestFromMembershipLFetching}
          fullWidth
        >
          <FormattedMessage
            defaultMessage="Unlink your {displayName} account"
            id="UXsv5H"
            values={{
              displayName: configuration.displayName,
            }}
          />
        </Button>
      </div>
    )
  }
  return null
}
